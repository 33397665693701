import { graphql } from 'gatsby';
import * as React from 'react';
import ContactInfo from '../../components/global/contact-info/contact-info';
import FullWidthImage from '../../components/global/full-width-image/full-width-image';
import HeaderAndText from '../../components/global/header-and-text/header-and-text';
import Layout from '../../components/global/layout/layout';

export const pageQuery = graphql`
  query RaeumlichkeitenQuery {
    site {
      siteMetadata {
        titleSuffix
        titleDivider
      }
    }
    raeumlichkeiten: file(name: {eq: "schloss-4"}) {
      childImageSharp {
        fluid(maxWidth: 2400, cropFocus: SOUTH) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allPrismicRoom(sort: {fields: data___order, order: ASC}) {
      nodes {
        data {
          title {
            text
          }
          subtitle {
            text
          }
          header_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 2400) {
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                }
              }
            }
          }
        }
        uid
      }
    }
  }
`

export default props => {
  return (
    <Layout wrapperClassName="bg-pattern-gold"
            title="Räumlichkeiten"
            description="Entdecken Sie unsere vielfältigen Räumlichkeiten für jeden Anlass. Von der romantischen Hochzeit bis zur professionellen Tagung bietet Schloss Westerburg für jeden Anlass den perfekten Rahmen."
            titleSuffix={props.data.site.siteMetadata.titleSuffix}
            titleDivider={props.data.site.siteMetadata.titleDivider}>
      <FullWidthImage tag="header" fluid={props.data.raeumlichkeiten.childImageSharp.fluid} />
      <HeaderAndText
        heading="Räumlichkeiten auf Schloss Westerburg"
        text="Entdecken Sie unsere vielfältigen Räumlichkeiten für jeden Anlass. Von der romantischen Hochzeit bis zur professionellen Tagung bietet Schloss Westerburg für jeden Anlass den perfekten Rahmen."
      />
      {
        props.data.allPrismicRoom.nodes.map((node, index) => (
          <React.Fragment key={index}>
            <FullWidthImage link={{ to: '/raeumlichkeiten/' + node.uid, text: node.data.title.text, subtitle: node.data.subtitle ? node.data.subtitle.text : undefined }} tag="section" fluid={node.data.header_image.localFile.childImageSharp.fluid} />
            <hr className="hr-divider"></hr>
          </React.Fragment>
        ))
      }
      <ContactInfo />
    </Layout>
  );
}
